
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { OrderTypeDetail } from '@/types/newOrder'

@Component
export default class Type extends Vue {
  @Prop() readonly typeShow!: boolean;
  private tableData: Array<OrderTypeDetail> = []
  private loading = false
  private isAddworker: number | null = null
  @Watch('typeShow')
  typeShowChange (value: boolean) {
    value && this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<OrderTypeDetail> }>(this.$apis.newOrder.selectFlowOrderTypeByList).then(res => {
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    if (this.isAddworker !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData.push({
        orderTypeName: '',
        orderTypeDescription: '',
        isInput: true
      })
      this.isAddworker = this.tableData.length - 1
      this.$nextTick(() => {
        (this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs.table as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  onSubmit (row: OrderTypeDetail) {
    if (row.orderTypeName && row.orderTypeDescription) {
      const info = row.orderType
        ? {
          orderType: row.orderType,
          orderTypeName: row.orderTypeName,
          orderTypeDescription: row.orderTypeDescription
        }
        : {
          orderTypeName: row.orderTypeName,
          orderTypeDescription: row.orderTypeDescription
        }

      const url = row.orderType
        ? this.$apis.newOrder.updateFlowOrderType
        : this.$apis.newOrder.insertFlowOrderType

      this.$axios.post(url, info).then(() => {
        this.getData()
        this.isAddworker = null
      })
    } else {
      this.$message({
        message: '请填写完整',
        type: 'warning'
      })
    }
  }

  onUpdate (row: OrderTypeDetail, index: number) {
    if (this.isAddworker !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData[index] = {
        ...row,
        isInput: true
      }
      this.isAddworker = index
      this.$set(this.tableData, index, this.tableData[index]) // 重新渲染表格数据
    }
  }

  // 取消
  onCancel (row: OrderTypeDetail, index: number) {
    this.isAddworker = null
    if (row.orderType) { // 是否为编辑
      this.tableData[index].isInput = false
    } else {
      this.tableData.splice(index, 1)
    }
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.newOrder.deleteFlowOrderType, {
        orderType: id
      }).then(() => {
        this.$message.success('删除成功')
        this.isAddworker = null
        this.getData()
      })
    })
  }

  // 关闭
  closeDialog () {
    // 数据清除
    this.isAddworker = null
    this.$emit('update:typeShow', false)
  }
}
