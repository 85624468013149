
import { Component, Vue } from 'vue-property-decorator'
import WorkOrderType from './Type.vue'
import WorkOrderClose from './Close.vue'
import WorkOrderTransfer from './Transfer.vue'
import WorkOrderFeedback from './Feedback.vue'
import WorkOrderAudit from './Audit.vue'
import { OrderDetail, OrderTypeDetail } from '@/types/newOrder.d'
import { ElForm } from 'element-ui/types/form'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'List',
  components: { WorkOrderType, WorkOrderClose, WorkOrderTransfer, WorkOrderFeedback, WorkOrderAudit }
})
@KeepAlive
export default class List extends Vue {
  private selectType = '1'
  private statusList = ['待反馈', '待审核', '待返工', '已完成', '已关闭']
  private typeList: Array<OrderTypeDetail> = []
  private date = []
  private searchInfo = {
    projectName: '',
    orderType: '',
    orderStatus: '',
    orderSubjectInId: ''
  }

  private loading = false
  private tableData: Array<OrderDetail>= []
  page = 1
  size = 10
  total = 0
  private typeShow = false
  private orderId = ''
  private projectId = ''
  private closeShow = false
  private transferShow = false
  private feedbackShow = false
  private auditShow = false

  get currentUserId () {
    const userInfo = window.sessionStorage.getItem('userInfo') || ''
    return userInfo ? JSON.parse(userInfo).userId : ''
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getType()
    this.getData()
  }

  getType () {
    this.$axios.get<{ total: number; list: Array<OrderTypeDetail> }>(this.$apis.newOrder.selectFlowOrderTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  getData () {
    this.loading = true
    const ctime = this.date && this.date.length ? {
      startTime: this.date[0],
      endTime: this.date[1]
    } : {}
    this.$axios.get<{ total: number; list: Array<OrderDetail> }>(this.$apis.newOrder.selectFlowOrderByPage, {
      ...this.searchInfo,
      ...ctime,
      selectType: this.selectType,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    this.$router.push({ name: 'orderAdd' })
  }

  // 菜单切换
  handleClick () {
    this.page = 1
    this.size = 10
    this.date = []
    ;(this.$refs.searchInfo as ElForm).resetFields()
    this.getData()
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'orderDetail',
      params: { id: id }
    })
  }

  // 待反馈，待返工，待审核
  onHandle (row: OrderTypeDetail) {
    this.orderId = row.orderId
    if (['1', '3'].includes(row.orderStatus)) {
      this.feedbackShow = true
    } else {
      this.auditShow = true
    }
  }

  // 转办
  onTransfer (orderId: string, projectId: string) {
    this.orderId = orderId
    this.projectId = projectId
    this.transferShow = true
  }

  onClose (id: string) {
    this.orderId = id
    this.closeShow = true
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
